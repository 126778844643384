<template>
  <div class="page-container">
    <div class="card-container">
      <div class="header">
        <img :src="logoIcon" width="98" alt="" class="logo" />
        <div class="title">{{ translateTitle("设置密码") }}</div>
      </div>
      <div class="form">
        <el-form ref="formRef" :model="formData" @submit.native.prevent>
          <el-form-item prop="password" :label="translateTitle('密码')">
            <el-input
              v-model="formData.password"
              :placeholder="translateTitle('请输入密码')"
              :type="showPassword ? 'text' : 'password'"
            >
              <template #suffix>
                <div class="suffix-btn">
                  <ClearIcon
                    v-if="!!formData.password"
                    style="margin-right: 10px"
                    @click="formData.password = ''"
                  />
                  <EyeIcon
                    v-if="showPassword"
                    @click="showPassword = !showPassword"
                  />
                  <EyeHideIcon v-else @click="showPassword = !showPassword" />
                </div>
              </template>
            </el-input>
            <div class="validate">
              <div class="item">
                <CheckSuccessIcon v-if="validObject.full" />
                <CheckFailIcon v-else />
                <span :class="{ success: validObject.full }">{{
                  translateTitle("6-20个字符，不包含空格")
                }}</span>
              </div>
              <div class="item">
                <CheckSuccessIcon v-if="validObject.number" />
                <CheckFailIcon v-else />
                <span :class="{ success: validObject.number }">{{
                  translateTitle("至少1个数字")
                }}</span>
              </div>
              <div class="item">
                <CheckSuccessIcon v-if="validObject.char" />
                <CheckFailIcon v-else />
                <span :class="{ success: validObject.char }">{{
                  translateTitle("至少1个字母")
                }}</span>
              </div>
            </div>
          </el-form-item>
        </el-form>

        <div class="privacy">
          {{ translateTitle("注册即表示我同意并理解4E的") }}
          <a :href="serviceUrl" target="_blank">{{
            translateTitle("用户协议")
          }}</a
          >{{ translateTitle("和")
          }}<a :href="privacyUrl" target="_blank">
            {{ translateTitle("《隐私条款》") }}
          </a>
        </div>

        <el-button
          class="next-btn"
          type="primary"
          :disabled="Object.values(validObject).some(state => !state)"
          @click="onRegister"
          >{{ translateTitle("下一步") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import logoIcon from "@/assets/img/users/logo.png";
import ClearIcon from "@/components/icons/ClearIcon.vue";
import { UCenterRequest } from "@/model/UCenterService";
import searchIcon from "@/assets/img/users/search.png";
import Cookies from "js-cookie";
import * as Account from "@/model/user/account";
import md5 from "md5";
import EyeIcon from "@/components/icons/EyeIcon.vue";
import EyeHideIcon from "@/components/icons/EyeHideIcon.vue";
import CheckFailIcon from "@/components/icons/CheckFailIcon.vue";
import CheckSuccessIcon from "@/components/icons/CheckSuccessIcon.vue";

const passwordReg =
  /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/;
export default {
  components: {
    ClearIcon,
    EyeIcon,
    EyeHideIcon,
    CheckFailIcon,
    CheckSuccessIcon,
  },
  data() {
    return {
      logoIcon,
      searchIcon,
      showInviteCode: true,
      inviteCode: this.$route.query.inviteCode || "",
      isShowGeet: false,

      formData: {
        password: "",
      },
      tokenRegister: this.$route.query.tokenRegister,
      area: this.$route.query.area || "",
      serviceUrl: "",
      privacyUrl: "",
      showPassword: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.user.language;
    },
    validObject() {
      return {
        full:
          passwordReg.test(this.formData.password) &&
          this.formData.password.length >= 6 &&
          this.formData.password.length <= 20,
        char: /[a-zA-Z]+/.test(this.formData.password),
        number: /[0-9]+/.test(this.formData.password),
      };
    },
  },
  created() {
    const clauseList = JSON.parse(sessionStorage.getItem("clause"));
    if (clauseList[0].language) {
      this.serviceUrl = clauseList[0].language[`url_${this.language}`];
    } else {
      this.serviceUrl = clauseList[0].url;
    }
    if (clauseList[1].language) {
      this.privacyUrl = clauseList[1].language[`url_${this.language}`];
    } else {
      this.privacyUrl = clauseList[1].url;
    }
  },
  methods: {
    async onRegister() {
      let res;
      if (this.$route.query.mobile) {
        const area = this.area[0] === "+" ? this.area.substring(1) : this.area;
        res = await UCenterRequest.registerMobile({
          mobile: this.$route.query.mobile,
          area,
          pwd: md5(this.formData.password),
          token_register: this.tokenRegister,
          invite_code: this.inviteCode,
        });
      } else {
        res = await UCenterRequest.registerEmail({
          email: this.$route.query.email,
          pwd: md5(this.formData.password),
          token_register: this.tokenRegister,
          invite_code: this.inviteCode,
        });
      }
      if (res.status === 200) {
        const userInfo = res.data;
        const { tokenInfo } = userInfo;
        this.$sensors.login(userInfo.user_id);
        Cookies.set("topcredit_exchange_t", tokenInfo.token);

        sessionStorage.setItem("topcredit_exchange_t", tokenInfo.token);

        Account.writeUserInfo(userInfo, true);
        this.$store.dispatch("user/getUserInfo").then(res => {
          let jump = this.$route.params.jump;
          if (jump) {
            //by TZ bakUrl貌似又有问题了。我这里解决的是bakUrl=true/false的问题。
            location.href = jump;
            return;
          } else {
            window.location.href = "/";
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/common.scss";

.next-btn {
  margin-top: 10px !important;
}

.privacy {
  margin-top: 100px !important;
  color: var(--Light04-text-c_t01, var(---c_t02, #5c5f69));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  margin-top: 14px;

  a {
    color: var(--Light04-text-c_t01, var(--7-c_m, #3348f6));
  }
}

.area {
  display: flex;
  height: 44px;
  align-items: center;
}

.mobile-list {
  width: 335px;
  height: 258px;
  overflow: auto;

  .mobile-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    color: var(--7-c_t01, #0d0e0e);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
}
</style>
